import { j as M } from "./jsx-runtime-B6kdoens.js";
import { a as J, _ as D } from "./index-28E3tTLh.js";
import * as j from "react";
import { forwardRef as _, useContext as le, useState as K, useEffect as C, createElement as u, useRef as g, createContext as Ke, useCallback as G, useMemo as Ve, Fragment as pe } from "react";
import We, { createPortal as je } from "react-dom";
import { b as P, a as W, $ as Ue } from "./index-CTDw4Pi4.js";
import { $ as Be } from "./index-egs6Xnuq.js";
import { $ as He, a as Xe } from "./index-Cp-Nu9_j.js";
import { $ as N, a as fe } from "./index-CZBInFmv.js";
import { $ as ze } from "./index-En-smfNl.js";
import { $ as Ye } from "./index-Cs5eRmmP.js";
import { a as be } from "./index-CvllCoev.js";
import { c as qe } from "./index-BV2fynjD.js";
import { c as U } from "./utils-CJ9afRe1.js";
import { I as Ge } from "./Icon-C5a5ARPP.js";
const oe = "dismissableLayer.update", Je = "dismissableLayer.pointerDownOutside", Qe = "dismissableLayer.focusOutside";
let ie;
const ve = /* @__PURE__ */ Ke({
  layers: /* @__PURE__ */ new Set(),
  layersWithOutsidePointerEventsDisabled: /* @__PURE__ */ new Set(),
  branches: /* @__PURE__ */ new Set()
}), Ze = /* @__PURE__ */ _((e, t) => {
  var o;
  const { disableOutsidePointerEvents: n = !1, onEscapeKeyDown: s, onPointerDownOutside: r, onFocusOutside: l, onInteractOutside: d, onDismiss: p, ...a } = e, f = le(ve), [b, A] = K(null), E = (o = b == null ? void 0 : b.ownerDocument) !== null && o !== void 0 ? o : globalThis == null ? void 0 : globalThis.document, [, F] = K({}), v = J(
    t,
    (m) => A(m)
  ), c = Array.from(f.layers), [h] = [
    ...f.layersWithOutsidePointerEventsDisabled
  ].slice(-1), $ = c.indexOf(h), w = b ? c.indexOf(b) : -1, T = f.layersWithOutsidePointerEventsDisabled.size > 0, x = w >= $, y = tt((m) => {
    const R = m.target, V = [
      ...f.branches
    ].some(
      (I) => I.contains(R)
    );
    !x || V || (r == null || r(m), d == null || d(m), m.defaultPrevented || p == null || p());
  }, E), O = ot((m) => {
    const R = m.target;
    [
      ...f.branches
    ].some(
      (I) => I.contains(R)
    ) || (l == null || l(m), d == null || d(m), m.defaultPrevented || p == null || p());
  }, E);
  return ze((m) => {
    w === f.layers.size - 1 && (s == null || s(m), !m.defaultPrevented && p && (m.preventDefault(), p()));
  }, E), C(() => {
    if (b)
      return n && (f.layersWithOutsidePointerEventsDisabled.size === 0 && (ie = E.body.style.pointerEvents, E.body.style.pointerEvents = "none"), f.layersWithOutsidePointerEventsDisabled.add(b)), f.layers.add(b), ce(), () => {
        n && f.layersWithOutsidePointerEventsDisabled.size === 1 && (E.body.style.pointerEvents = ie);
      };
  }, [
    b,
    E,
    n,
    f
  ]), C(() => () => {
    b && (f.layers.delete(b), f.layersWithOutsidePointerEventsDisabled.delete(b), ce());
  }, [
    b,
    f
  ]), C(() => {
    const m = () => F({});
    return document.addEventListener(oe, m), () => document.removeEventListener(oe, m);
  }, []), /* @__PURE__ */ u(N.div, D({}, a, {
    ref: v,
    style: {
      pointerEvents: T ? x ? "auto" : "none" : void 0,
      ...e.style
    },
    onFocusCapture: P(e.onFocusCapture, O.onFocusCapture),
    onBlurCapture: P(e.onBlurCapture, O.onBlurCapture),
    onPointerDownCapture: P(e.onPointerDownCapture, y.onPointerDownCapture)
  }));
}), et = /* @__PURE__ */ _((e, t) => {
  const o = le(ve), n = g(null), s = J(t, n);
  return C(() => {
    const r = n.current;
    if (r)
      return o.branches.add(r), () => {
        o.branches.delete(r);
      };
  }, [
    o.branches
  ]), /* @__PURE__ */ u(N.div, D({}, e, {
    ref: s
  }));
});
function tt(e, t = globalThis == null ? void 0 : globalThis.document) {
  const o = W(e), n = g(!1), s = g(() => {
  });
  return C(() => {
    const r = (d) => {
      if (d.target && !n.current) {
        let a = function() {
          $e(Je, o, p, {
            discrete: !0
          });
        };
        const p = {
          originalEvent: d
        };
        d.pointerType === "touch" ? (t.removeEventListener("click", s.current), s.current = a, t.addEventListener("click", s.current, {
          once: !0
        })) : a();
      } else
        t.removeEventListener("click", s.current);
      n.current = !1;
    }, l = window.setTimeout(() => {
      t.addEventListener("pointerdown", r);
    }, 0);
    return () => {
      window.clearTimeout(l), t.removeEventListener("pointerdown", r), t.removeEventListener("click", s.current);
    };
  }, [
    t,
    o
  ]), {
    // ensures we check React component tree (not just DOM tree)
    onPointerDownCapture: () => n.current = !0
  };
}
function ot(e, t = globalThis == null ? void 0 : globalThis.document) {
  const o = W(e), n = g(!1);
  return C(() => {
    const s = (r) => {
      r.target && !n.current && $e(Qe, o, {
        originalEvent: r
      }, {
        discrete: !1
      });
    };
    return t.addEventListener("focusin", s), () => t.removeEventListener("focusin", s);
  }, [
    t,
    o
  ]), {
    onFocusCapture: () => n.current = !0,
    onBlurCapture: () => n.current = !1
  };
}
function ce() {
  const e = new CustomEvent(oe);
  document.dispatchEvent(e);
}
function $e(e, t, o, { discrete: n }) {
  const s = o.originalEvent.target, r = new CustomEvent(e, {
    bubbles: !1,
    cancelable: !0,
    detail: o
  });
  t && s.addEventListener(e, t, {
    once: !0
  }), n ? fe(s, r) : s.dispatchEvent(r);
}
const nt = Ze, st = et, rt = /* @__PURE__ */ _((e, t) => {
  var o;
  const { container: n = globalThis == null || (o = globalThis.document) === null || o === void 0 ? void 0 : o.body, ...s } = e;
  return n ? /* @__PURE__ */ We.createPortal(/* @__PURE__ */ u(N.div, D({}, s, {
    ref: t
  })), n) : null;
}), me = "ToastProvider", [re, at, it] = Be("Toast"), [we, Ht] = He("Toast", [
  it
]), [ct, Q] = we(me), Te = (e) => {
  const { __scopeToast: t, label: o = "Notification", duration: n = 5e3, swipeDirection: s = "right", swipeThreshold: r = 50, children: l } = e, [d, p] = K(null), [a, f] = K(0), b = g(!1), A = g(!1);
  return /* @__PURE__ */ u(re.Provider, {
    scope: t
  }, /* @__PURE__ */ u(ct, {
    scope: t,
    label: o,
    duration: n,
    swipeDirection: s,
    swipeThreshold: r,
    toastCount: a,
    viewport: d,
    onViewportChange: p,
    onToastAdd: G(
      () => f(
        (E) => E + 1
      ),
      []
    ),
    onToastRemove: G(
      () => f(
        (E) => E - 1
      ),
      []
    ),
    isFocusedToastEscapeKeyDownRef: b,
    isClosePausedRef: A
  }, l));
};
Te.propTypes = {
  label(e) {
    if (e.label && typeof e.label == "string" && !e.label.trim()) {
      const t = `Invalid prop \`label\` supplied to \`${me}\`. Expected non-empty \`string\`.`;
      return new Error(t);
    }
    return null;
  }
};
const dt = "ToastViewport", ut = [
  "F8"
], ne = "toast.viewportPause", se = "toast.viewportResume", lt = /* @__PURE__ */ _((e, t) => {
  const { __scopeToast: o, hotkey: n = ut, label: s = "Notifications ({hotkey})", ...r } = e, l = Q(dt, o), d = at(o), p = g(null), a = g(null), f = g(null), b = g(null), A = J(t, b, l.onViewportChange), E = n.join("+").replace(/Key/g, "").replace(/Digit/g, ""), F = l.toastCount > 0;
  C(() => {
    const c = (h) => {
      var $;
      n.every(
        (T) => h[T] || h.code === T
      ) && (($ = b.current) === null || $ === void 0 || $.focus());
    };
    return document.addEventListener("keydown", c), () => document.removeEventListener("keydown", c);
  }, [
    n
  ]), C(() => {
    const c = p.current, h = b.current;
    if (F && c && h) {
      const $ = () => {
        if (!l.isClosePausedRef.current) {
          const y = new CustomEvent(ne);
          h.dispatchEvent(y), l.isClosePausedRef.current = !0;
        }
      }, w = () => {
        if (l.isClosePausedRef.current) {
          const y = new CustomEvent(se);
          h.dispatchEvent(y), l.isClosePausedRef.current = !1;
        }
      }, T = (y) => {
        !c.contains(y.relatedTarget) && w();
      }, x = () => {
        c.contains(document.activeElement) || w();
      };
      return c.addEventListener("focusin", $), c.addEventListener("focusout", T), c.addEventListener("pointermove", $), c.addEventListener("pointerleave", x), window.addEventListener("blur", $), window.addEventListener("focus", w), () => {
        c.removeEventListener("focusin", $), c.removeEventListener("focusout", T), c.removeEventListener("pointermove", $), c.removeEventListener("pointerleave", x), window.removeEventListener("blur", $), window.removeEventListener("focus", w);
      };
    }
  }, [
    F,
    l.isClosePausedRef
  ]);
  const v = G(({ tabbingDirection: c }) => {
    const $ = d().map((w) => {
      const T = w.ref.current, x = [
        T,
        ...Dt(T)
      ];
      return c === "forwards" ? x : x.reverse();
    });
    return (c === "forwards" ? $.reverse() : $).flat();
  }, [
    d
  ]);
  return C(() => {
    const c = b.current;
    if (c) {
      const h = ($) => {
        const w = $.altKey || $.ctrlKey || $.metaKey;
        if ($.key === "Tab" && !w) {
          const m = document.activeElement, R = $.shiftKey;
          if ($.target === c && R) {
            var x;
            (x = a.current) === null || x === void 0 || x.focus();
            return;
          }
          const k = v({
            tabbingDirection: R ? "backwards" : "forwards"
          }), X = k.findIndex(
            (i) => i === m
          );
          if (te(k.slice(X + 1))) $.preventDefault();
          else {
            var y, O;
            R ? (y = a.current) === null || y === void 0 || y.focus() : (O = f.current) === null || O === void 0 || O.focus();
          }
        }
      };
      return c.addEventListener("keydown", h), () => c.removeEventListener("keydown", h);
    }
  }, [
    d,
    v
  ]), /* @__PURE__ */ u(st, {
    ref: p,
    role: "region",
    "aria-label": s.replace("{hotkey}", E),
    tabIndex: -1,
    style: {
      pointerEvents: F ? void 0 : "none"
    }
  }, F && /* @__PURE__ */ u(de, {
    ref: a,
    onFocusFromOutsideViewport: () => {
      const c = v({
        tabbingDirection: "forwards"
      });
      te(c);
    }
  }), /* @__PURE__ */ u(re.Slot, {
    scope: o
  }, /* @__PURE__ */ u(N.ol, D({
    tabIndex: -1
  }, r, {
    ref: A
  }))), F && /* @__PURE__ */ u(de, {
    ref: f,
    onFocusFromOutsideViewport: () => {
      const c = v({
        tabbingDirection: "backwards"
      });
      te(c);
    }
  }));
}), pt = "ToastFocusProxy", de = /* @__PURE__ */ _((e, t) => {
  const { __scopeToast: o, onFocusFromOutsideViewport: n, ...s } = e, r = Q(pt, o);
  return /* @__PURE__ */ u(be, D({
    "aria-hidden": !0,
    tabIndex: 0
  }, s, {
    ref: t,
    style: {
      position: "fixed"
    },
    onFocus: (l) => {
      var d;
      const p = l.relatedTarget;
      !((d = r.viewport) !== null && d !== void 0 && d.contains(p)) && n();
    }
  }));
}), Z = "Toast", ft = "toast.swipeStart", bt = "toast.swipeMove", vt = "toast.swipeCancel", $t = "toast.swipeEnd", mt = /* @__PURE__ */ _((e, t) => {
  const { forceMount: o, open: n, defaultOpen: s, onOpenChange: r, ...l } = e, [d = !0, p] = Ue({
    prop: n,
    defaultProp: s,
    onChange: r
  });
  return /* @__PURE__ */ u(Ye, {
    present: o || d
  }, /* @__PURE__ */ u(xe, D({
    open: d
  }, l, {
    ref: t,
    onClose: () => p(!1),
    onPause: W(e.onPause),
    onResume: W(e.onResume),
    onSwipeStart: P(e.onSwipeStart, (a) => {
      a.currentTarget.setAttribute("data-swipe", "start");
    }),
    onSwipeMove: P(e.onSwipeMove, (a) => {
      const { x: f, y: b } = a.detail.delta;
      a.currentTarget.setAttribute("data-swipe", "move"), a.currentTarget.style.setProperty("--radix-toast-swipe-move-x", `${f}px`), a.currentTarget.style.setProperty("--radix-toast-swipe-move-y", `${b}px`);
    }),
    onSwipeCancel: P(e.onSwipeCancel, (a) => {
      a.currentTarget.setAttribute("data-swipe", "cancel"), a.currentTarget.style.removeProperty("--radix-toast-swipe-move-x"), a.currentTarget.style.removeProperty("--radix-toast-swipe-move-y"), a.currentTarget.style.removeProperty("--radix-toast-swipe-end-x"), a.currentTarget.style.removeProperty("--radix-toast-swipe-end-y");
    }),
    onSwipeEnd: P(e.onSwipeEnd, (a) => {
      const { x: f, y: b } = a.detail.delta;
      a.currentTarget.setAttribute("data-swipe", "end"), a.currentTarget.style.removeProperty("--radix-toast-swipe-move-x"), a.currentTarget.style.removeProperty("--radix-toast-swipe-move-y"), a.currentTarget.style.setProperty("--radix-toast-swipe-end-x", `${f}px`), a.currentTarget.style.setProperty("--radix-toast-swipe-end-y", `${b}px`), p(!1);
    })
  })));
}), [wt, Tt] = we(Z, {
  onClose() {
  }
}), xe = /* @__PURE__ */ _((e, t) => {
  const { __scopeToast: o, type: n = "foreground", duration: s, open: r, onClose: l, onEscapeKeyDown: d, onPause: p, onResume: a, onSwipeStart: f, onSwipeMove: b, onSwipeCancel: A, onSwipeEnd: E, ...F } = e, v = Q(Z, o), [c, h] = K(null), $ = J(
    t,
    (i) => h(i)
  ), w = g(null), T = g(null), x = s || v.duration, y = g(0), O = g(x), m = g(0), { onToastAdd: R, onToastRemove: V } = v, I = W(() => {
    var i;
    (c == null ? void 0 : c.contains(document.activeElement)) && ((i = v.viewport) === null || i === void 0 || i.focus()), l();
  }), k = G((i) => {
    !i || i === 1 / 0 || (window.clearTimeout(m.current), y.current = (/* @__PURE__ */ new Date()).getTime(), m.current = window.setTimeout(I, i));
  }, [
    I
  ]);
  C(() => {
    const i = v.viewport;
    if (i) {
      const S = () => {
        k(O.current), a == null || a();
      }, L = () => {
        const B = (/* @__PURE__ */ new Date()).getTime() - y.current;
        O.current = O.current - B, window.clearTimeout(m.current), p == null || p();
      };
      return i.addEventListener(ne, L), i.addEventListener(se, S), () => {
        i.removeEventListener(ne, L), i.removeEventListener(se, S);
      };
    }
  }, [
    v.viewport,
    x,
    p,
    a,
    k
  ]), C(() => {
    r && !v.isClosePausedRef.current && k(x);
  }, [
    r,
    x,
    v.isClosePausedRef,
    k
  ]), C(() => (R(), () => V()), [
    R,
    V
  ]);
  const X = Ve(() => c ? he(c) : null, [
    c
  ]);
  return v.viewport ? /* @__PURE__ */ u(pe, null, X && /* @__PURE__ */ u(xt, {
    __scopeToast: o,
    role: "status",
    "aria-live": n === "foreground" ? "assertive" : "polite",
    "aria-atomic": !0
  }, X), /* @__PURE__ */ u(wt, {
    scope: o,
    onClose: I
  }, /* @__PURE__ */ je(/* @__PURE__ */ u(re.ItemSlot, {
    scope: o
  }, /* @__PURE__ */ u(nt, {
    asChild: !0,
    onEscapeKeyDown: P(d, () => {
      v.isFocusedToastEscapeKeyDownRef.current || I(), v.isFocusedToastEscapeKeyDownRef.current = !1;
    })
  }, /* @__PURE__ */ u(N.li, D({
    // Ensure toasts are announced as status list or status when focused
    role: "status",
    "aria-live": "off",
    "aria-atomic": !0,
    tabIndex: 0,
    "data-state": r ? "open" : "closed",
    "data-swipe-direction": v.swipeDirection
  }, F, {
    ref: $,
    style: {
      userSelect: "none",
      touchAction: "none",
      ...e.style
    },
    onKeyDown: P(e.onKeyDown, (i) => {
      i.key === "Escape" && (d == null || d(i.nativeEvent), i.nativeEvent.defaultPrevented || (v.isFocusedToastEscapeKeyDownRef.current = !0, I()));
    }),
    onPointerDown: P(e.onPointerDown, (i) => {
      i.button === 0 && (w.current = {
        x: i.clientX,
        y: i.clientY
      });
    }),
    onPointerMove: P(e.onPointerMove, (i) => {
      if (!w.current) return;
      const S = i.clientX - w.current.x, L = i.clientY - w.current.y, B = !!T.current, H = [
        "left",
        "right"
      ].includes(v.swipeDirection), z = [
        "left",
        "up"
      ].includes(v.swipeDirection) ? Math.min : Math.max, ke = H ? z(0, S) : 0, Me = H ? 0 : z(0, L), ee = i.pointerType === "touch" ? 10 : 2, Y = {
        x: ke,
        y: Me
      }, ae = {
        originalEvent: i,
        delta: Y
      };
      B ? (T.current = Y, q(bt, b, ae, {
        discrete: !1
      })) : ue(Y, v.swipeDirection, ee) ? (T.current = Y, q(ft, f, ae, {
        discrete: !1
      }), i.target.setPointerCapture(i.pointerId)) : (Math.abs(S) > ee || Math.abs(L) > ee) && (w.current = null);
    }),
    onPointerUp: P(e.onPointerUp, (i) => {
      const S = T.current, L = i.target;
      if (L.hasPointerCapture(i.pointerId) && L.releasePointerCapture(i.pointerId), T.current = null, w.current = null, S) {
        const B = i.currentTarget, H = {
          originalEvent: i,
          delta: S
        };
        ue(S, v.swipeDirection, v.swipeThreshold) ? q($t, E, H, {
          discrete: !0
        }) : q(vt, A, H, {
          discrete: !0
        }), B.addEventListener(
          "click",
          (z) => z.preventDefault(),
          {
            once: !0
          }
        );
      }
    })
  })))), v.viewport))) : null;
});
xe.propTypes = {
  type(e) {
    if (e.type && ![
      "foreground",
      "background"
    ].includes(e.type)) {
      const t = `Invalid prop \`type\` supplied to \`${Z}\`. Expected \`foreground | background\`.`;
      return new Error(t);
    }
    return null;
  }
};
const xt = (e) => {
  const { __scopeToast: t, children: o, ...n } = e, s = Q(Z, t), [r, l] = K(!1), [d, p] = K(!1);
  return Pt(
    () => l(!0)
  ), C(() => {
    const a = window.setTimeout(
      () => p(!0),
      1e3
    );
    return () => window.clearTimeout(a);
  }, []), d ? null : /* @__PURE__ */ u(rt, {
    asChild: !0
  }, /* @__PURE__ */ u(be, n, r && /* @__PURE__ */ u(pe, null, s.label, " ", o)));
}, Et = /* @__PURE__ */ _((e, t) => {
  const { __scopeToast: o, ...n } = e;
  return /* @__PURE__ */ u(N.div, D({}, n, {
    ref: t
  }));
}), yt = /* @__PURE__ */ _((e, t) => {
  const { __scopeToast: o, ...n } = e;
  return /* @__PURE__ */ u(N.div, D({}, n, {
    ref: t
  }));
}), gt = "ToastAction", Ee = /* @__PURE__ */ _((e, t) => {
  const { altText: o, ...n } = e;
  return o ? /* @__PURE__ */ u(ge, {
    altText: o,
    asChild: !0
  }, /* @__PURE__ */ u(ye, D({}, n, {
    ref: t
  }))) : null;
});
Ee.propTypes = {
  altText(e) {
    return e.altText ? null : new Error(`Missing prop \`altText\` expected on \`${gt}\``);
  }
};
const ht = "ToastClose", ye = /* @__PURE__ */ _((e, t) => {
  const { __scopeToast: o, ...n } = e, s = Tt(ht, o);
  return /* @__PURE__ */ u(ge, {
    asChild: !0
  }, /* @__PURE__ */ u(N.button, D({
    type: "button"
  }, n, {
    ref: t,
    onClick: P(e.onClick, s.onClose)
  })));
}), ge = /* @__PURE__ */ _((e, t) => {
  const { __scopeToast: o, altText: n, ...s } = e;
  return /* @__PURE__ */ u(N.div, D({
    "data-radix-toast-announce-exclude": "",
    "data-radix-toast-announce-alt": n || void 0
  }, s, {
    ref: t
  }));
});
function he(e) {
  const t = [];
  return Array.from(e.childNodes).forEach((n) => {
    if (n.nodeType === n.TEXT_NODE && n.textContent && t.push(n.textContent), Ct(n)) {
      const s = n.ariaHidden || n.hidden || n.style.display === "none", r = n.dataset.radixToastAnnounceExclude === "";
      if (!s)
        if (r) {
          const l = n.dataset.radixToastAnnounceAlt;
          l && t.push(l);
        } else t.push(...he(n));
    }
  }), t;
}
function q(e, t, o, { discrete: n }) {
  const s = o.originalEvent.currentTarget, r = new CustomEvent(e, {
    bubbles: !0,
    cancelable: !0,
    detail: o
  });
  t && s.addEventListener(e, t, {
    once: !0
  }), n ? fe(s, r) : s.dispatchEvent(r);
}
const ue = (e, t, o = 0) => {
  const n = Math.abs(e.x), s = Math.abs(e.y), r = n > s;
  return t === "left" || t === "right" ? r && n > o : !r && s > o;
};
function Pt(e = () => {
}) {
  const t = W(e);
  Xe(() => {
    let o = 0, n = 0;
    return o = window.requestAnimationFrame(
      () => n = window.requestAnimationFrame(t)
    ), () => {
      window.cancelAnimationFrame(o), window.cancelAnimationFrame(n);
    };
  }, [
    t
  ]);
}
function Ct(e) {
  return e.nodeType === e.ELEMENT_NODE;
}
function Dt(e) {
  const t = [], o = document.createTreeWalker(e, NodeFilter.SHOW_ELEMENT, {
    acceptNode: (n) => {
      const s = n.tagName === "INPUT" && n.type === "hidden";
      return n.disabled || n.hidden || s ? NodeFilter.FILTER_SKIP : n.tabIndex >= 0 ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
    }
  });
  for (; o.nextNode(); ) t.push(o.currentNode);
  return t;
}
function te(e) {
  const t = document.activeElement;
  return e.some((o) => o === t ? !0 : (o.focus(), document.activeElement !== t));
}
const _t = Te, Pe = lt, Ce = mt, De = Et, _e = yt, Oe = Ee, Re = ye, Ie = j.forwardRef(({ className: e, ...t }, o) => /* @__PURE__ */ M.jsx(
  Oe,
  {
    ref: o,
    className: U(
      "inline-flex h-8 shrink-0 items-center justify-center rounded-md border bg-transparent px-3 text-sm font-medium ring-offset-background transition-colors hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 group-[.destructive]:border-muted/40 group-[.destructive]:hover:border-destructive/30 group-[.destructive]:hover:bg-destructive group-[.destructive]:hover:text-destructive-foreground group-[.destructive]:focus:ring-destructive",
      e
    ),
    ...t
  }
));
Ie.displayName = Oe.displayName;
const Se = j.forwardRef(({ className: e, ...t }, o) => /* @__PURE__ */ M.jsx(
  Re,
  {
    ref: o,
    className: U(
      "absolute right-2 top-2 rounded-md p-1 text-foreground/50 opacity-0 transition-opacity hover:text-foreground focus:opacity-100 focus:outline-none focus:ring-2 group-hover:opacity-100 group-[.destructive]:text-red-300 group-[.destructive]:hover:text-red-50 group-[.destructive]:focus:ring-red-400 group-[.destructive]:focus:ring-offset-red-600",
      e
    ),
    "toast-close": "",
    ...t,
    children: /* @__PURE__ */ M.jsx(Ge, { name: "Cancel", width: 20, strokeWidth: 1.5 })
  }
));
Se.displayName = Re.displayName;
const Fe = j.forwardRef(({ className: e, ...t }, o) => /* @__PURE__ */ M.jsx(
  _e,
  {
    ref: o,
    className: U("text-sm opacity-90", e),
    ...t
  }
));
Fe.displayName = _e.displayName;
const Ne = j.forwardRef(({ className: e, ...t }, o) => /* @__PURE__ */ M.jsx(
  De,
  {
    ref: o,
    className: U("text-sm font-semibold", e),
    ...t
  }
));
Ne.displayName = De.displayName;
const Le = j.forwardRef(({ className: e, ...t }, o) => /* @__PURE__ */ M.jsx(
  Pe,
  {
    ref: o,
    "data-toast": "true",
    className: U(
      "fixed top-0 z-full-screen flex max-h-screen w-full flex-col-reverse gap-3 p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col md:max-w-[420px]",
      e
    ),
    ...t
  }
));
Le.displayName = Pe.displayName;
const Ot = _t, Rt = qe(
  "group pointer-events-auto relative flex w-full items-center justify-between space-x-4 overflow-hidden rounded-md border p-6 pr-8 shadow-lg transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full",
  {
    variants: {
      variant: {
        default: "border bg-background text-foreground",
        destructive: "destructive group border-destructive bg-destructive text-destructive-foreground",
        success: "success py-3 px-4 shadow-none group border-success-border bg-success text-success-foreground"
      }
    },
    defaultVariants: {
      variant: "default"
    }
  }
), Ae = j.forwardRef(({ className: e, variant: t, ...o }, n) => /* @__PURE__ */ M.jsx(
  Ce,
  {
    ref: n,
    className: U(Rt({ variant: t }), e),
    ...o
  }
));
Ae.displayName = Ce.displayName;
const Xt = Object.assign(Ae, {
  Action: Ie,
  Close: Se,
  Description: Fe,
  Provider: Ot,
  Title: Ne,
  Viewport: Le
});
export {
  Xt as T
};
